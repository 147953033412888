import { useQuery, useMutation, useQueryClient, QueryClient } from '@tanstack/vue-query';
import upvotesApi from '@/launch_pack/api/upvotes';
export function useInitialUpvoteStaticQuery(launchId, initialData, options = {}) {
    return useQuery({
        queryKey: ['upvotes', launchId],
        staleTime: Infinity,
        initialData,
        ...options,
    });
}
function incrementQueryData(queryClient, launchId) {
    queryClient.setQueryData(['upvotes', launchId], (old) => (old ? old + 1 : 1));
}
function decrementQueryData(queryClient, launchId) {
    queryClient.setQueryData(['upvotes', launchId], (old) => (old ? old - 1 : 0));
}
export function useCreateUpvoteMutation(launchId, options = {}) {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: () => upvotesApi.create(launchId),
        onMutate: () => incrementQueryData(queryClient, launchId),
        onError: () => decrementQueryData(queryClient, launchId),
        ...options,
    });
}
export function useDeleteUpvoteMutation(launchId, options = {}) {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: () => upvotesApi.delete(launchId),
        onMutate: () => decrementQueryData(queryClient, launchId),
        onError: () => incrementQueryData(queryClient, launchId),
        ...options,
    });
}
