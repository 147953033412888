import api from '@/api/api';
const prefix = process.env.RAILS_ENV === 'production' ? '' : '/launch-pack';
export default {
    create(launchId) {
        const path = `${prefix}/api/v1/launches/${launchId}/upvotes`;
        return api({
            method: 'post',
            url: path,
        });
    },
    delete(launchId) {
        const path = `${prefix}/api/v1/launches/${launchId}/upvotes`;
        return api({
            method: 'delete',
            url: path,
        });
    },
};
