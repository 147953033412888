import { createApp, onBeforeMount, onMounted, computed, provide } from 'vue';
import { camelizeKeys } from 'humps';
import InlineSvg from 'vue-inline-svg';
import linkifyHtml from 'linkify-html';
import { createPinia } from 'pinia';
import { VueQueryPlugin } from '@tanstack/vue-query';
import * as Sentry from '@sentry/browser';
import PostHog from 'posthog-js';
import * as Integrations from '@sentry/integrations';
import '@/css/kalio.scss';
import '@/launch_pack/stylesheets/launch.scss';
import store from '@/store';
import '@/config/vee-validate';
import i18n from '@/plugins/i18n';
import activateTargetBlankLinks from '@/utils/target_blank_links';
import useScreen from '@/hooks/screen';
import { currentUserKey, currentUserCompanyKey, flashMessagesKey } from '@/utils/keys';
import { useRolesStore, useUserStore } from '@/stores';
import { identifyUser } from '@/utils/analytics';
// Base Components
import KalioButton from '@/components/shared/kalio-button.vue';
import KalioFlashAlert from '@/components/shared/kalio-flash-alert.vue';
import LandingMobileNavbarHamburger from '@/components/landing/mobile-navbar-hamburger.vue';
// Components
import LaunchIndexCard from '@/launch_pack/components/launch-index-card.vue';
import LaunchShowCard from '@/launch_pack/components/launch-show-card.vue';

const linkify = {
  mounted: (element) => {
    element.innerHTML = linkifyHtml(element.innerText);
  },
};

document.addEventListener('DOMContentLoaded', () => {
  const app = createApp({
    setup() {
      const userStore = useUserStore();
      provide(
        currentUserKey,
        computed(() => userStore.currentUser),
      );

      onMounted(() => {
        if (process.env.RAILS_ENV === 'production' && userStore.currentUser) {
          if (process.env.POSTHOG_API_KEY) {
            identifyUser(userStore.currentUser.id, {
              id: userStore.currentUser.id,
              email: userStore.currentUser.email,
              username: userStore.currentUser.fullName,
            });
          }

          if (process.env.SENTRY_DSN) {
            Sentry.setUser({
              id: userStore.currentUser.id,
              email: userStore.currentUser.email,
              username: userStore.currentUser.fullName,
            });
          }
        }
      });

      const rolesStore = useRolesStore();

      onBeforeMount(() => {
        if (window.currentUser) {
          userStore.setCurrentUser(camelizeKeys(window.currentUser));
        }
        if (window.currentUserRoles) {
          rolesStore.setCurrentUserRoles(camelizeKeys(window.currentUserRoles));
        }
        if (window.currentUserCompany) {
          provide(
            currentUserCompanyKey,
            camelizeKeys(window.currentUserCompany),
          );
        }

        provide(flashMessagesKey, window.flashMessages);
      });

      onMounted(() => {
        if (document.getElementsByClassName('editable-content').length > 0) {
          activateTargetBlankLinks('editable-content');
        }
      });

      const { screen } = useScreen();

      return {
        screen,
      };
    },
  });

  if (process.env.RAILS_ENV === 'production') {
    if (process.env.POSTHOG_API_KEY) {
      /* eslint-disable camelcase */
      PostHog.init(process.env.POSTHOG_API_KEY, {
        api_host: `https://${process.env.POSTHOG_HOST}`,
        session_recording: {
          maskAllInputs: true, // Important - this needs to be true for the below function to be called
          maskInputFn: (text, element) => {
            if (element?.attributes.type?.value === 'password') {
              return '*'.repeat(text.length);
            }

            return text;
          },
        },
      });
      /* eslint-enable camelcase */
    }

    if (process.env.SENTRY_DSN) {
      Sentry.init({
        dsn: process.env.SENTRY_DSN,
        integrations: [
          new Integrations.Vue({ Vue: app, attachProps: true }),
          new PostHog.SentryIntegration(
            PostHog,
            'Platanus Ventures',
            process.env.POSTHOG_PROJECT_ID,
          ),
        ],
      });
    }
  }

  app.config.globalProperties.$filters = {
    camelizeKeys,
  };

  app.config.globalProperties.window = window;

  const pinia = createPinia();
  app.use(pinia);
  app.use(i18n);
  app.use(store);
  app.use(VueQueryPlugin, {
    queryClientConfig: {
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
        },
      },
    },
  });

  app.component('inline-svg', InlineSvg);
  app.directive('linkified', linkify);
  // Shared Component registration
  app.component('kalio-button', KalioButton);
  app.component('kalio-flash-alert', KalioFlashAlert);
  app.component('landing-mobile-navbar-hamburger', LandingMobileNavbarHamburger);
  // Component registration
  app.component('launch-index-card', LaunchIndexCard);
  app.component('launch-show-card', LaunchShowCard);
  app.mount('#vue-app');

  return app;
});
